import * as React from "react";
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Icon } from "@iconify/react";
import link2Outline from "@iconify/icons-eva/link-2-outline";
import { Card, Stack, Button, TextField, Container } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { toaster } from "../utils/toast";
import { web3Services } from "../services/web3.service";
import { services } from "../services/index";
import { getContractInstance } from "../helper/contractHelper";
import Page from "../components/Page";
import { compactAddress } from "src/contractData/function";
import { getWeb3 } from "src/helper/metamask";
// ----------------------------------------------------------------------

class Lottery extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      txnStatus: { status: false, msg: "" },
      snapShotData: [],
      snapShotId: null,
      snapshotIndex: "",
      totalUsers: 0,
      tier: "",
      noOfUser: null,
      records: [],
      recordId: "",
      randonNumber: "",
      ownerId: null,
      web3Data: {
        isLoggedIn: false,
        accounts: [],
      },
    };
  }

  componentDidMount = () => {
    this.getSnapshots();
    this.getRecordIds();
    this.connectMetamask(true);
  };

  // form on submit
  onSubmit = (e) => {
    e.preventDefault();
    if (+this.state.noOfUser > +this.state.totalUsers) {
      return this.genrateError("No of users cant be greater than total users");
    }
    this.genrateLottery();
  };

  genrateError = (text) => {
    toaster(text, "error");
  };

  //   get snapshots
  getSnapshots = async () => {
    try {
      const url = `/user/snapshotData`;

      const sendRequest = await services.Get(url);

      if (sendRequest.status === 200) {
        this.setState({
          snapShotData: sendRequest.data.data.getSnapshotDetails,
        });
      }
    } catch (err) {}
  };

  genrateLottery = async () => {
    try {
      const url = `/user/genrateRandom`;

      const data = {
        snapshotId: this.state.snapShotId,
        requestNo: this.state.recordId,
        num: this.state.noOfUser,
      };
      this.setState({
        txnStatus: { status: true },
      });

      const sendRequest = await services.Post(url, data);

      if (sendRequest.status === 200) {
        this.setState({
          txnStatus: { status: false },
        });
        this.genrateSuccess("Your Request has been received ");
      }
    } catch (err) {
      this.setState(
        {
          txnStatus: { status: false },
        },
        () => {
          this.genrateError(err.response.data.message);
        }
      );
      if (err.response) {
      }
    }
  };

   //toDo: This is the RNG V1 contract code don't remove...
  // previous function call
  // get reord ids
  // getRecordIds = async () => {
  //   try {
  //     const getInstance = await getContractInstance();
  //     const getRecordId = await getInstance.methods.requestNumber().call();
  //     const getOwnerId = await getInstance.methods.owner().call();

  //     if (getRecordId) {
  //       let recordIds = [];
  //       for (let i = 0; i < +getRecordId; i++) {
  //         recordIds.push(i + 1);
  //       }
  //       this.setState({ records: recordIds, ownerId: getOwnerId });
  //     }
  //   } catch (err) {
  //     console.log("erro rin getting record Did", err);
  //   }
  // };

  
  getRecordIds = async () => {
    try {
      const getInstance = await getContractInstance();
      const getOwnerId = await getInstance.methods.owner().call();
      const requestCount = await getInstance.methods.getRequestIdsLength().call()
    

     
        let recordIds = [];
        for (let i = 0; i < requestCount; i++) {
          const requestId = await getInstance.methods.requestIds(i).call();
          recordIds.push(requestId);
        }
        this.setState({ records: recordIds, ownerId: getOwnerId });
      
    } catch (err) {
      console.log("Error in getting record IDs", err);
    }
  }
  

  // connect metamask
  connectMetamask = async (fetch) => {
    if (fetch) {
      let _web3Data = await getWeb3();
      this.setState({ web3Data: _web3Data });
    } else
      try {
        const getAcountDetails = await web3Services.enableMetamask();
        this.setState({ web3Data: getAcountDetails });
      } catch (err) {
        console.log("error is:", err);
      }
  };

  onFormSubmit = (e) => {
    if (!this.state.web3Data.isLoggedIn) {
      return this.genrateError("Please connect metamask ");
    } else if (
      this.state.web3Data.isLoggedIn &&
      this.state.web3Data.accounts[0].toLowerCase() !==
        this.state.ownerId.toLowerCase()
    ) {
      return this.genrateError(
        "Please connect contract with owner contract address "
      );
    } else {
      this.genrateRandomNumbers();
    }
  };

  genrateRandomNumbers = async () => {
    const contractInstance = await getContractInstance();
    this.setState({
      txnStatus: { status: true, msg: "Please sign the trasaction" },
    });
    await contractInstance.methods
      .getRandomNumber()
      .send({ from: this.state.web3Data.accounts[0] })
      .on("transactionHash", (hash) => {
        this.setState({
          txnStatus: { status: true, msg: "Transaction is in progress" },
        });
      })
      .on("receipt", (receipt) => {
        this.setState({ txnStatus: { status: false, msg: "" } });

        this.onReciept();
      })
      .on("error", (error) => {
        this.setState({ txnStatus: { status: false, msg: "" } });

        this.onTransactionError(error);
      });
  };
  onTransactionError(error) {
    if (error.code === 4001) {
      this.genrateError("Transaction cancelled by user");
    } else if (error.code === -32602) {
      this.genrateError("Wrong parameters");
    } else if (error.code === -32603) {
      this.genrateError("Internal Error");
    } else if (error.code === -32002) {
      this.genrateError("Pending Previous request");
    } else {
      this.genrateError("Transaction has been reverted");
    }
  }
  onReciept = () => {
    this.genrateSuccess(
      "Random No genrated Please wait for 5 mins for genrating the lottery"
    );
  };

  genrateSuccess = (text) => {
    toaster(text, "success");
  };
  render() {
    return (
      <Page title="Generate Request Number | Reformo Snapshot">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Button
              variant="contained"
              onClick={() => {
                this.onFormSubmit();
              }}
              sx={{ background: "linear-gradient(90deg, #dc1fff 0%, #03e1ff 50%, #00ffa3 100%)" }}
            >
              Generate Random Numbers
            </Button>
            <Button
              variant="contained"
              // component={RouterLink}
              onClick={() => {
                this.connectMetamask();
              }}
              startIcon={
                this.state.web3Data.isLoggedIn ? null : (
                  <Icon icon={link2Outline} />
                )
              }
              sx={{ background: "linear-gradient(90deg, #dc1fff 0%, #03e1ff 50%, #00ffa3 100%)" }}
            >
              {this.state.web3Data.isLoggedIn
                ? compactAddress(this.state.web3Data.accounts[0])
                : "Connect Metamask"}
            </Button>
          </Stack>
          <Card style={{ padding: "20px", backgroundColor: '#151517' }}>
            <form onSubmit={this.onSubmit}>
              <Stack spacing={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Snapshot
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.snapshotIndex}
                    label="Select Snapshot"
                    onChange={(e) => {
                      this.setState({
                        snapshotIndex: e.target.value,
                        snapShotId: this.state.snapShotData[e.target.value]._id,
                        totalUsers:
                          this.state.snapShotData[e.target.value].totalUsers,
                        tier: this.state.snapShotData[e.target.value].tier,
                      });
                    }}
                  >
                    {this.state.snapShotData.map((snapshot, index) => (
                      <MenuItem
                        value={`${index}`}
                        disabled={snapshot.isSnapshotDone}
                        style={{backgroundColor: '#151517'}}
                      >
                        {" "}
                        {snapshot._id}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  autoComplete="users"
                  type="text"
                  label="Total Users"
                  onChange={(e) => {
                    const re = /^[0-9]+\.?[0-9]*$/;
                    if (e.target.value && re.test(e.target.value)) {
                      this.setState({ endBlock: e.target.value });
                    }
                  }}
                  value={this.state.totalUsers}
                  disabled
                />
                <TextField
                  fullWidth
                  autoComplete="tier"
                  type="text"
                  label="Tier"
                  value={this.state.tier}
                  placeholder="Tier"
                  disabled
                  required
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Request Id
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Request Id"
                    onChange={(e) => {
                      this.setState({
                        recordId: e.target.value,
                      });
                    }}
                    value={this.state.recordId}
                    required
                  >
                    {this.state.records.map((record) => (
                      <MenuItem value={`${record}`} style={{backgroundColor: '#151517'}}>{record}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  autoComplete="shortlist"
                  type="text"
                  label="No of users to get shortlisted"
                  onChange={(e) => {
                    const re = /^[0-9]+\.?[0-9]*$/;
                    if (re.test(e.target.value)) {
                      this.setState({ noOfUser: e.target.value });
                    } else this.setState({ noOfUser: "" });
                  }}
                  value={this.state.noOfUser}
                  required
                />
              </Stack>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={this.state.txnStatus.status}
                style={{ marginTop: "25px",  color: "#000000" }}
                sx={{ background: "linear-gradient(90deg, #dc1fff 0%, #03e1ff 50%, #00ffa3 100%)" }}
              >
                Generate Lottery
              </LoadingButton>
            </form>
          </Card>
        </Container>
      </Page>
    );
  }
}

export default Lottery;
