import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import fileTextOutline from "@iconify/icons-eva/file-text-outline";
import FormControl from "@mui/material/FormControl";
import { toaster } from "../utils/toast";

// import { Card, Stack, TextField, Container, Typography } from '@mui/material';

// material
import {
  Card,
  Button,
  Container,
  Stack,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
} from "@mui/material";
// components
import Page from "../components/Page";
import { services } from "../services/index";

// ----------------------------------------------------------------------

export default function GenrateSnapshot() {
  const [isLoading, setLoading] = useState(false);
  const [country, setCountry] = useState([]);
  const [igoName, setIgoName] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedIgoName, setSelectedIgoName] = useState([]);

  useEffect(() => {
    async function getCountryList() {
      const url = `/user/getUniqueCountries`;
      const sendRequest = await services.Get(url);

      if (sendRequest.status === 200) {
        setCountry(sendRequest.data.data);
      } else {
        setLoading(false);
      }
    }
    getCountryList();
  }, []);

  useEffect(() => {
    async function getIgoList() {
      const url = `/apply-proj/list?approved`;
      const sendRequest = await services.Get(url);

      if (sendRequest.status === 200) {
        setIgoName(sendRequest.data.data);
      } else {
        setLoading(false);
      }
    }
    getIgoList();
  }, []);






  const genrateSnapshot = async () => {
    setLoading(true);
    let url = `/user/getUserStake`;

    if (selectedCountry) {
      url = `/user/getUserStake?country=${selectedCountry}`;
    }

    if (selectedIgoName) {
      url = `/user/getUserStake?name=${selectedIgoName}`;
    }

    if (selectedIgoName && selectedCountry) {
      url = `/user/getUserStake?country=${selectedCountry}&name=${selectedIgoName}`;
    }
    // this.setState({ showLoader: true });
    const sendRequest = await services.Get(url);

    if (sendRequest.status === 200) {
      setLoading(false);
      return toaster(`Your request have been received`, "success");
    } else {
      setLoading(false);
    }
  };

  return (
    <Page title="Generate Snapshot | Reformo Snapshot">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Generate Snapshot
          </Typography>
        </Stack>

        <Card style={{ padding: "20px", backgroundColor: '#151517' }}>
          <Stack spacing={3}>
            {/* <TextField
              fullWidth
              autoComplete="Igo Name"
              value={igoName}
              onChange={(e) => {
                setIgoName(e.target.value);
              }}
              label="Name of Igo"
              required
            /> */}

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select IDO name
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Country"
                onChange={(e) => {
                  setSelectedIgoName(e.target.value);
                }}
                value={selectedIgoName}
                required
              >
                <MenuItem value={""} style={{backgroundColor: '#151517'}}></MenuItem>
                {igoName.map((igoName) => (
                  <MenuItem value={`${igoName?.projectName}`} style={{backgroundColor: '#151517'}}>
                  {igoName?.projectName}
                </MenuItem>
                ))}
              </Select>
            </FormControl>


            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Country to Exclude
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Country"
                onChange={(e) => {
                  setSelectedCountry(e.target.value);
                }}
                value={selectedCountry}
                required
              >
                <MenuItem value={""} style={{backgroundColor: '#151517'}}></MenuItem>
                {country.map((country) => (
                  <MenuItem value={`${country}`} style={{backgroundColor: '#151517'}}>{country}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Button
            variant="contained"
            // component={RouterLink}
            // to='/'
            onClick={() => {
              genrateSnapshot();
            }}
            disabled={isLoading}
            size="large"
            style={{ marginTop: "40px", color: "#000000"  }}
            startIcon={<Icon icon={fileTextOutline} />}
            sx={{ background: "linear-gradient(90deg, #dc1fff 0%, #03e1ff 50%, #00ffa3 100%)" }}
          >
            Generate Snapshot
          </Button>
        </Card>
      </Container>
    </Page>
  );
}
