import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Icon } from "@iconify/react";
import link2Outline from "@iconify/icons-eva/link-2-outline";
import { web3Services } from "../services/web3.service";
import { services } from "../services/index";
import { getContractInstance } from "../helper/contractHelper";
import { Card, Stack, Button, TextField, Container } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Page from "../components/Page";
import { toaster } from "../utils/toast";
import { compactAddress } from "src/contractData/function";

// ----------------------------------------------------------------------
class ApproveFile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      txnStatus: { status: false, msg: "" },
      snapShotData: [],
      showLoader: false,
      snapShotId: null,
      snapshotIndex: "",
      totalUsers: 0,
      tier: "",
      noOfUser: null,
      records: [1, 2],
      recordId: "",
      fileHash: "",
      ownerId: null,
      web3Data: {
        isLoggedIn: false,
        accounts: [],
      },
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.web3Data.accounts[0] !== prevState.web3Data.accounts[0]) {
      this.getNetworkId();
    }
  }

  componentDidMount = () => {
    this.getSnapshots();
    this.getRecordIds();
    this.connectMetamask();
  };

  async changeNetwork(val) {
    let ethereum = window.ethereum;
    const data = [
      {
        chainId: "0x61",
        chainName: "BSC Testnet",
        nativeCurrency: {
          name: "BNB",
          symbol: "BNB",
          decimals: 18,
        },
        rpcUrls: ["https://rpc.ankr.com/bsc_testnet_chapel"],
        blockExplorerUrls: ["https://dataseed1.binance.org/"],
      },
    ];

    /* eslint-disable */
    try {
      await ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: data,
        })
        .then((resp) => {
          this.getRecordIds();
        });
      // .catch(() => console.log("error"));
    } catch {
      console.log("catched");
    }
  }

  async getNetworkId() {
    try {
      const id = await window.ethereum.request({ method: "eth_chainId" });
      if (id !== "0x38") this.changeNetwork();
    } catch (error) {
      return 1;
    }
  }
  // form on submit
  onSubmit = (e) => {
    if (!this.state.web3Data.isLoggedIn) {
      return this.genrateError("Please connect metamask ");
    } else if (
      this.state.web3Data.isLoggedIn &&
      this.state.web3Data.accounts[0].toLowerCase() !==
        this.state.ownerId.toLowerCase()
    ) {
      return this.genrateError(
        "Please connect contract with owner contract address "
      );
    } else {
      this.genrateRecordId();
    }
  };

  genrateRecordId = async () => {
    const contractInstance = await getContractInstance();
    this.setState({
      txnStatus: { status: true, msg: "Please sign the trasaction" },
    });
    await contractInstance.methods
      .addHash(this.state.fileHash)
      .send({ from: this.state.web3Data.accounts[0] })
      .on("transactionHash", (hash) => {
        this.setState({
          txnStatus: { status: true, msg: "Transaction is in progress" },
        });
      })
      .on("receipt", (receipt) => {
        this.setState({ txnStatus: { status: false, msg: "" } });

        this.onReciept();
      })
      .on("error", (error) => {
        this.setState({ txnStatus: { status: false, msg: "" } });
        this.onTransactionError(error);
      });
  };
  onTransactionError(error) {
    if (error.code === 4001) {
      this.genrateError("Transaction cancelled by user");
    } else if (error.code === -32602) {
      this.genrateError("Wrong parameters");
    } else if (error.code === -32603) {
      this.genrateError("Internal Error");
    } else if (error.code === -32002) {
      this.genrateError("Pending Previous request");
    } else {
      this.genrateError("Transaction has been reverted");
    }
  }

  genrateError = (text) => {
    toast(text, "error");
  };

  genrateSuccess = (text) => {
    toaster(text, "success");
  };

  onReciept = () => {
    this.genrateSuccess("Record Id genrated");
  };

  // get owner ID
  getRecordIds = async () => {
    try {
      const getInstance = await getContractInstance();

      const getOwnerId = await getInstance.methods.owner().call();

      this.setState({ ownerId: getOwnerId });
    } catch (err) {
      console.log("erro rin getting record Did", err);
    }
  };

  //   get snapshots
  getSnapshots = async () => {
    try {
      const url = `/user/snapshotData`;

      const sendRequest = await services.Get(url);

      if (sendRequest.status === 200) {
        this.setState({
          snapShotData: sendRequest.data.data.getSnapshotDetails,
        });
      }
    } catch (err) {}
  };

  // connect metamask
  connectMetamask = async () => {
    try {
      const getAcountDetails = await web3Services.enableMetamask();
      this.setState({ web3Data: getAcountDetails });
    } catch (err) {
      console.log("error is:", err);
    }
  };
  render() {
    return (
      <Page title="Generate Request Number | Reformo Snapshot">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            mb={5}
          >
            <Button
              variant="contained"
              // component={RouterLink}
              onClick={() => {
                this.connectMetamask();
              }}
              startIcon={
                this.state.web3Data.isLoggedIn ? null : (
                  <Icon icon={link2Outline} />
                )
              }
              sx={{ background: "linear-gradient(90deg, #dc1fff 0%, #03e1ff 50%, #00ffa3 100%)" }}
            >
              {this.state.web3Data.isLoggedIn
                ? compactAddress(this.state.web3Data.accounts[0])
                : "Connect Metamask"}
            </Button>
          </Stack>
          <Card style={{ padding: "20px", backgroundColor: '#151517' }}>
            <Stack spacing={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Snapshot
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.snapshotIndex}
                  label="Select Snapshot"
                  onChange={(e) => {
                    this.setState({
                      snapshotIndex: e.target.value,
                      snapShotId: this.state.snapShotData[e.target.value]._id,
                      totalUsers:
                        this.state.snapShotData[e.target.value].totalUsers,
                      tier: this.state.snapShotData[e.target.value].tier,
                      fileHash:
                        this.state.snapShotData[e.target.value].fileHash,
                    });
                  }}
                >
                  {this.state.snapShotData.map((snapshot, index) => (
                    <MenuItem
                      value={`${index}`}
                      disabled={snapshot.isSnapshotDone}
                      style={{backgroundColor: '#151517'}}
                    >
                      {snapshot._id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                fullWidth
                autoComplete="users"
                type="text"
                label="Total Users"
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e) => {
                  const re = /^[0-9]+\.?[0-9]*$/;
                  if (e.target.value && re.test(e.target.value)) {
                    this.setState({ endBlock: e.target.value });
                  }
                }}
                value={this.state.totalUsers}
                placeholder=""
              />
              <TextField
                fullWidth
                autoComplete="tier"
                type="text"
                label="Tier"
                InputProps={{
                  readOnly: true,
                }}
                value={this.state.tier}
                placeholder=""
              />
              <TextField
                fullWidth
                autoComplete="hash"
                type="text"
                InputProps={{
                  readOnly: true,
                }}
                label="File Hash"
                value={this.state.fileHash}
                required
              />
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={() => {
                this.onSubmit();
              }}
              loading={this.state.showLoader}
              style={{ marginTop: "25px", color: "#000000" }}
              sx={{ background: "linear-gradient(90deg, #dc1fff 0%, #03e1ff 50%, #00ffa3 100%)" }}
            >
              Generate Request Number
            </LoadingButton>
          </Card>
        </Container>
      </Page>
    );
  }
}

export default ApproveFile;
