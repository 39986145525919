import * as React from "react";
import { useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Card, Stack, Container } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Page from "../components/Page";
import { services } from "../services/index";
import { toaster } from "../utils/toast";
// import Autocomplete from 'src/theme/overrides/Autocomplete';
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { unstable_renderSubtreeIntoContainer } from "react-dom";

// ----------------------------------------------------------------------

export default function GenerateTierList() {
  const [isLoading, setLoading] = useState(false);
  const [network, setNetwork] = React.useState("");
  const [isSubmitting, setSubmit] = React.useState(false);
  const [country, setCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [igoName, setIgoName] = useState([]);
  const [selectedIgoName, setSelectedIgoName] = useState("");
  // const [searchedIGO, setSeachedIGO] = useState([]);
  // const [inputVal, setInputVal] = useState();
  // const [selectedIGO, setSelectedIGO] = useState();

  useEffect(() => {
    async function getCountryList() {
      const url = `/user/getUniqueCountries`;
      const sendRequest = await services.Get(url);

      if (sendRequest.status === 200) {
        setCountry(sendRequest.data.data);
      } else {
        // setLoading(false);
      }
    }
    getCountryList();
  }, []);

  

  useEffect(() => {
    async function getIgoList() {
      const url = `/apply-proj/list?approved`;
      const sendRequest = await services.Get(url);

      if (sendRequest.status === 200) {
        setIgoName(sendRequest.data.data);
      } else {
        // setLoading(false);
      }
    }
    getIgoList();
  }, []);




  const handleChange = (event) => {
    setNetwork(event.target.value);
  };

  const generateTierList = async () => {
    setSubmit(true);

    let url = `/user/genrateCsv?tier=${network}&sendEmail=true`;
    // if (selectedIGO) {
    //   url = url + `&projectId=${selectedIGO._id}`;
    // }
    if (selectedIgoName) {
      url = url + `&igoName=${selectedIgoName}`;
    }


    if (selectedCountry) {
      url = url + `&country=${selectedCountry}`;
    }

    // if (selectedIgoName && selectedCountry) {
    //   url = `/user/getUserStake?country=${selectedCountry}&name=${selectedIgoName}`;
    // }

    // this.setState({ showLoader: true });
    const sendRequest = await services.Get(url);

    if (sendRequest.status === 200) {
      setSubmit(false);
      return toaster(`Your request have been received`, "success");
    } else {
      setSubmit(false);
    }
  };
  // const searchIGO = async (val) => {
  //   console.log("called", val);
  //   if (val.length >= 3) {
  //     let url = `/project/list?search=${val}`;
  //     const sendRequest = await services.Get(url);
  //     if (sendRequest.status === 200) {
  //       setSeachedIGO(sendRequest.data.data);
  //       console.log(sendRequest.data.data);
  //     } else {
  //       console.log("err", sendRequest);
  //     }
  //     console.log(val);
  //   }
  // };
  // console.log(selectedIGO);
  return (
    <Page title="Generate Tier List | Reformo Snapshot">
      <Container>
        <Card style={{ padding: "20px", backgroundColor: '#151517' }}>
          <Stack spacing={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Snapshot Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={network}
                label="Select Contract Type"
                onChange={handleChange}
              >
                <MenuItem value={`tier0`} style={{backgroundColor: '#151517'}}>Tier 0 (0 - 249.00)</MenuItem>
                <MenuItem value={`tier1`} style={{backgroundColor: '#151517'}}>Tier 1 (250 - 999.99)</MenuItem>
                <MenuItem value={`tier2`} style={{backgroundColor: '#151517'}}>Tier 2 (999.99 - 2499.99)</MenuItem>
                <MenuItem value={`tier3`} style={{backgroundColor: '#151517'}}>Tier 3 (2500 - 4999.99)</MenuItem>
                <MenuItem value={`tier4`} style={{backgroundColor: '#151517'}}>Tier 4 (5000 - 7499.99)</MenuItem>
                <MenuItem value={`tier5`} style={{backgroundColor: '#151517'}}>Tier 5 (7500 - 9999.99)</MenuItem>
                <MenuItem value={`tier6`} style={{backgroundColor: '#151517'}}>Tier 6 (10,000 - 24,999.99)</MenuItem>
                <MenuItem value={`tier7`} style={{backgroundColor: '#151517'}}>Tier 7 (25,000 - 49,999.99)</MenuItem>
                <MenuItem value={`tier8`} style={{backgroundColor: '#151517'}}>Tier 8 (50,000 - 99,999.99)</MenuItem>
                <MenuItem value={`tier9`} style={{backgroundColor: '#151517'}}>Tier 9 (100000+)</MenuItem>
              </Select>
            </FormControl>

            {/* <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={searchedIGO}
              value={selectedIGO}
              onChange={(event, newValue) => {
                setSelectedIGO(newValue);
              }}
              inputValue={inputVal}
              onInputChange={(event, newInputValue) => {
                setInputVal(newInputValue);
                searchIGO(newInputValue);
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Select IGO" />
              )}
            /> */}

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select IDO name
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Country"
                onChange={(e) => {
                  setSelectedIgoName(e.target.value);
                }}
                value={selectedIgoName}
                required
              >
                <MenuItem value={""} style={{backgroundColor: '#151517'}}></MenuItem>
                {igoName.map((igoName) => (
                  <MenuItem value={`${igoName?.projectName}`} style={{backgroundColor: '#151517'}}>
                  {igoName?.projectName}
                </MenuItem>
                ))}
              </Select>
            </FormControl>




            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Country to Exclude
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value=''
                label="Select Country"
                onChange={(e) => {
                  setSelectedCountry(e.target.value);
                }}
                value={selectedCountry}
                required
              >
                <MenuItem value={""} style={{backgroundColor: '#151517'}}></MenuItem>
                {country.map((country) => (
                  <MenuItem value={`${country}`} style={{backgroundColor: '#151517'}}>{country}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            onClick={() => {
              generateTierList();
            }}
            style={{ marginTop: "25px", color: "#000000" }}
            sx={{ background: "linear-gradient(90deg, #dc1fff 0%, #03e1ff 50%, #00ffa3 100%)" }}
          >
            Generate Tier List
          </LoadingButton>
        </Card>
      </Container>
    </Page>
  );
}
