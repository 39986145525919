import axios from "axios";
import CONSTANT from "src/constant";

// production server
// export default axios.create({
//   baseURL: 'http://localhost:4000/api/v1',
// });
// local;

export default axios.create({
  baseURL:
 //toDo: This code commented for test purpose

    CONSTANT.ENV === "DEVELOPMENT"
    ? process.env.REACT_APP_DEV_API
    : process.env.REACT_APP_DEV_API,


});
