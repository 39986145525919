import * as Yup from "yup";
import * as React from "react";
import { useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useParams } from "react-router-dom";
import styled from "styled-components";
// material

import {
  Card,
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  Container,
  Typography,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Page from "../components/Page";
import { services } from "src/services";
import { web3 } from "src/web3";
import { toaster } from "src/utils/toast";
// ----------------------------------------------------------------------

export default function LoginForm(props) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState("");
  const [poolObj, setPoolObj] = useState({
    poolName: "",
    contractAddress: "",
    tokenAddress: "",
    loyalityPoints: "",
    tokenName: "",
    contractType: "",
    lpTokenAddress: "",
    url: "",
  });
  const LoginSchema = Yup.object().shape({
    email: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      navigate("/dashboard", { replace: true });
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;
  const { id } = useParams();
  console.log(id);

  useEffect(() => {
    if (id) {
      getPoolDetails(id);
    }
  }, []);

  const getPoolDetails = async (poolId) => {
    const url = `/pools/list/${poolId}`;
    const sendRequest = await services.Get(url);
    console.log("here", sendRequest);

    if (sendRequest.status === 200) {
      const pool = sendRequest.data.data;
      setShowPassword(pool.poolName);
      setPoolObj({
        ...poolObj,
        poolName: pool.poolName,
        contractAddress: pool.contractAddress,
        tokenAddress: pool.tokenAddress,
        loyalityPoints: pool.loyalityPoints,
        tokenName: pool.tokenName,
        contractType: pool.contractType,
        lpTokenAddress: pool.lpTokenAddress,
        url: pool.url ? pool.url : "",
      });
    }
  };
  console.log(poolObj.poolName);

  const onSubmit = async () => {
    try {
      const data = poolObj;
      // const url = "/pools/add";
      const url = !id ? `/pools/add` : `/pools/update/${id}`;

      const sendRequest = await services.Post(url, data);
      console.log("called", sendRequest);
      if (sendRequest.status === 200) {
        // this.genrateSuccess("Pool Added Sucessfully");
        toaster(
          `${id ? "Pool Updated Sucessfully" : "Pool Added Sucessfully"}`,
          "success"
        );

        return props.navigate("/dashboard/pools");
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.props.history.push("/login");
          localStorage.removeItem("token");
        }

        if (err.response.status === 400) {
          console.log("response is:", err.response);
        }
      }
    }
  };
  const upDatePool = (e) => {
    if (
      e.target.name.includes("Address") &&
      !web3.utils.isAddress(e.target.value)
    )
      return toaster(`${"Please enter valid address"}`, "error");

    setPoolObj({ ...poolObj, [e.target.name]: e.target.value });
  };
  console.log(poolObj);
  return (
    <Page title="Add Claim Pools | Reformo Snapshot">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Add New Pool
          </Typography>
        </Stack>

        <Card style={{ padding: "20px", backgroundColor: '#151517' }}>
          {/* <FormikProvider value={formik}> */}
          {/* <Form autoComplete="off" noValidate onSubmit={handleSubmit}> */}
          <Stack spacing={3}>
            <TextField
              type="text"
              label="Pool Name"
              multiline
              value={poolObj.poolName}
              name="poolName"
              fullWidth
              // {...getFieldProps("poolName")}
              onChange={(e) => upDatePool(e)}
            />
            <ParentField>
              <TextField
                value={poolObj.contractAddress}
                name="contractAddress"
                fullWidth
                type="text"
                label="Contract Address"
                // {...getFieldProps("contractAddress")}
                onChange={(e) => upDatePool(e)}
              />
              <button type="button">Deploy the Contract</button>
            </ParentField>

            <TextField
              value={poolObj.tokenAddress}
              name="tokenAddress"
              fullWidth
              type="text"
              label="Token Address"
              // {...getFieldProps("tokenAddress")}
              onChange={(e) => upDatePool(e)}
            />

            <TextField
              value={poolObj.loyalityPoints}
              name="loyalityPoints"
              fullWidth
              type="number"
              label="Loyality Points"
              // {...getFieldProps("loyalityPoints")}
              onChange={(e) => upDatePool(e)}
            />

            <TextField
              value={poolObj.url}
              name="url"
              fullWidth
              type="text"
              label="Pool Url"
              // {...getFieldProps("url")}
              onChange={(e) => upDatePool(e)}
            />

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Token Name
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="tokenName"
                label="Select Token Name"
                value={poolObj.tokenName}
                onChange={(e) => upDatePool(e)}
              >
                <MenuItem value={"RST"} style={{backgroundColor: '#151517'}}>RST</MenuItem>
                {/* <MenuItem value={"snft"}>SNFT</MenuItem> */}
                <MenuItem value={"TBUSD"} style={{backgroundColor: '#151517'}}>TRST</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Contract Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="contractType"
                label="Select Contract Type"
                value={poolObj.contractType}
                onChange={(e) => upDatePool(e)}
              >
                <MenuItem value={"farming"} style={{backgroundColor: '#151517'}}>Farming</MenuItem>
                <MenuItem value={"staking"} style={{backgroundColor: '#151517'}}>Staking</MenuItem>
              </Select>
            </FormControl>
            {poolObj.contractType ? (
              <TextField
                value={poolObj.lpTokenAddress}
                name="lpTokenAddress"
                fullWidth
                type="text"
                label="LP token address"
                // {...getFieldProps("url")}
                onChange={(e) => upDatePool(e)}
              />
            ) : null}
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            style={{ marginTop: "25px" }}
            onClick={() => onSubmit()}
            sx={{ background: "linear-gradient(90deg, #dc1fff 0%, #03e1ff 50%, #00ffa3 100%)" }}
          >
            {id ? "Update" : "Add New Pool"}
          </LoadingButton>
          {/* </Form> */}
          {/* </FormikProvider> */}
        </Card>
      </Container>
    </Page>
  );
}

const ParentField = styled.div`
  position: relative;
  button {
    position: absolute;
    right: 10px;
    top: calc(50% - 20px);
    color: #fff;
    background: linear-gradient(267.83deg, #FBAE48 6.21%, #F05C87 107.97%);
    border-radius: 10px;
    padding: 10px 22px;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    font-family: Public Sans, sans-serif;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
`;
