import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import shoppingBagFill from "@iconify/icons-eva/shopping-bag-fill";
import fileTextFill from "@iconify/icons-eva/file-text-fill";
// import lockFill from '@iconify/icons-eva/lock-fill';
import GridFill from "@iconify/icons-eva/grid-fill";
import BarChartOutline from "@iconify/icons-eva/bar-chart-outline";
import BookOpen from "@iconify/icons-eva/clipboard-fill";
import FileFill from "@iconify/icons-eva/file-text-fill";
import { red } from "@mui/material/colors";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} color={red} />;

export const adminsidebarConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: getIcon(pieChart2Fill),
  },
  {
    title: "user",
    path: "/dashboard/user",
    icon: getIcon(peopleFill),
  },
  {
    title: "manage claims",
    path: "/dashboard/manageclaims",
    icon: getIcon(shoppingBagFill),
  },
  // {
  //   title: "Approve Project",
  //   path: "/dashboard/approveProjects",
  //   icon: getIcon(shoppingBagFill),
  // },
  {
    title: "manage IDO",
    path: "/dashboard/manageigo",
    icon: getIcon(shoppingBagFill),
  },

  {
    title: "Snapshot",
    path: "/dashboard/generatesnapshot",
    icon: getIcon(fileTextFill),
    children: [
      {
        title: "Generate Snasphot",
        path: "/dashboard/generatesnapshot",
        icon: getIcon(fileTextFill),
        // icon: getIcon(fileTextFill)
        // name: 'Child31',
        // url: '/generatetierlist',
      },
      {
        title: "Generate tierlist",
        path: "/dashboard/generatetierlist",
        icon: getIcon(fileTextFill),
        // icon: getIcon(fileTextFill)
        // name: 'Child31',
        // url: '/generatetierlist',
      },
      {
        title: "Generate tierlist for Reg",
        path: "/dashboard/generatetierlistreg",
        icon: getIcon(fileTextFill)
      },
      {
        title: "Generate RequestNo",
        path: "/dashboard/generaterequestnumber",
        icon: getIcon(fileTextFill),
      },

      {
        title: "Generate lottery",
        path: "/dashboard/generatelottery",
        icon: getIcon(fileTextFill),
      },
    ],
  },
  {
    title: "pools",
    path: "/dashboard/pools",
    icon: getIcon(GridFill),
  },
  {
    title: "network",
    path: "/dashboard/network",
    icon: getIcon(BarChartOutline),
  },
  // {
  //   title: "addIgo",
  //   path: "/dashboard/addIgo",
  //   icon: getIcon(BarChartOutline),
  // },
  {
    title: "generate report",
    path: "/dashboard/generatereport",
    icon: getIcon(BookOpen),
  },
  {
    title: "generate csv",
    path: "/dashboard/generatecsv",
    icon: getIcon(FileFill),
  },
  {
    title: "Check Logs",
    path: "/dashboard/checkLogs",
    icon: getIcon(FileFill),
  },
  // {
  //   title: " Snapshot Functions",
  //   path: "/dashboard/snapshotFunctions",
  //   icon: getIcon(FileFill),
  // },
  // {
  //   title: "Historial Snapshot Functions",
  //   path: "/dashboard/historicalSnapshotFunctions",
  //   icon: getIcon(FileFill),
  // },
];
export const superAdminSidebarConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: getIcon(pieChart2Fill),
  },
  {
    title: "Approve Project",
    path: "/dashboard/approveProjects",
    icon: getIcon(shoppingBagFill),
  },
];
// export default adminsidebarConfig;
