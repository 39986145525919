const getContractAddresses = {
 RaiserToken: "0x641C37C5BedDc99cE7671f29EaD6dcE67Fdc49d2",
  1: "0xfd930ed8b633629e931fa03a217d92fd9ff7d8c7",
  7: "0xdf080b0ED1C7B3c9EC22bbFB9bD557F8BE3749D6",
  // 14: "0x6CEb5aE2D116f9162af9fEa72E7C5c0F5C3c6AD5",
  30: "0x291257E3bb074606564Fe1EB827fb6ec2fD44B23",
  60: "0x487e9e136a4bc352bf07fcFD7FEfD7FA4da9b556",
  90: "0xe0516F9339D26172546438CBEF9fd9C71eEef149",
  180: "0x6584495e0f8c8C756647bEae5fCBD28a83abF5E4",
  365: "0xCD10506a3f40db08c8CDB4bE76041Edd8b77965e",
  farmingContract: "0x436F41e2f06DEB9B7ED1D07ad0dA0945fE1F4356",
};
export default getContractAddresses;
