// import { Icon } from '@iconify/react';
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
// import plusFill from '@iconify/icons-eva/plus-fill';
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../components/_dashboard/user";
import { services } from "../services";
//
import USERLIST from "../_mocks_/user";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "walletaddress", label: "Wallet Address", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "kycStatus", label: "Kyc Status", alignRight: false },
  { id: "approvedTimestamp", label: "Approved timestamp", alignRight: false },
  { id: "tier", label: "Tier", alignRight: false },
  { id: "countryCode", label: "Country", alignRight: false },
  { id: "Record Added", label: "Record Added  ", alignRight: false },
];

// ----------------------------------------------------------------------

export default function User() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [walletAddress, setWalletAddress] = useState("");
  const [email, setEmail] = useState("");
  const [users, setUsers] = useState([]);
  const [pageNo, setpageNo] = useState(1);
  const [country, setCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();

  const [pagination, setpagination] = useState({
    limit: 0,
    pageNo: 0,
    totalPages: 0,
    totalRecords: 0,
  });
  useEffect(() => {
    async function getCountryList() {
      const url = `/user/getUniqueCountries`;
      const sendRequest = await services.Get(url);

      if (sendRequest.status === 200) {
        setCountry(sendRequest.data.data);
      } else {
        // setLoading(false);
      }
    }
    getCountryList();
  }, []);

  const getUserList = async (pageNo) => {
    let url = `/user/list?page=${pageNo}`;
    if (walletAddress) {
      url = `/user/list?page=${pageNo}&address=${walletAddress}`;
    }
    if (email) {
      url = `/user/list?page=${pageNo}&email=${email}`;
    }
    if (!walletAddress && !email && selectedCountry) {
      url = `/user/list?page=${pageNo}&country=${selectedCountry}`;
    }

    const getUserList = await services.Get(url);

    if (getUserList.status === 200) {
      const list = getUserList.data.data;
      const pagination = getUserList.data.pagination;

      setUsers(list);
      setpagination(pagination);
    }
  };

  useEffect(() => {
    getUserList(pageNo);
  }, [pageNo]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setpageNo(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByAddress = (event) => {
    setWalletAddress(event.target.value);
    if (email) setEmail("");
    if (selectedCountry) setSelectedCountry("");
  };

  const handleFilterByEmail = (event) => {
    setEmail(event.target.value);
    if (walletAddress) setWalletAddress("");
    if (selectedCountry) setSelectedCountry("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      getUserList(1);
    }
  };
  useEffect(() => {
    if (selectedCountry) getUserList(1);
  }, [selectedCountry]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const isUserNotFound = users.length === 0;

  return (
    <Page title="Reformo Admin">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Kyc Verified User List
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            New User
          </Button> */}
        </Stack>

        <Card style={{backgroundColor: '#151517'}}>
          <UserListToolbar
            filterAddress={walletAddress}
            onFilterAddress={handleFilterByAddress}
            handleKeyDown={handleKeyDown}
            filterEmail={email}
            onFilterEmail={handleFilterByEmail}
            country={country}
            setSelectedCountry={setSelectedCountry}
            selectedCountry={selectedCountry}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {users.map((row) => {
                    const {
                      _id,
                      walletAddress,
                      name,
                      email,
                      kycStatus,
                      approvedTimestamp,
                      tier,
                      country,
                      createdAt,
                    } = row;
                    return (
                      <TableRow hover key={_id} tabIndex={-1}>
                        <TableCell spacing={2}>{walletAddress}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={
                              (kycStatus === "rejected" && "pending") ||
                              "success"
                            }
                          >
                            {sentenceCase(kycStatus ? kycStatus : "")}
                            {/* {kycStatus} */}
                          </Label>
                        </TableCell>
                        <TableCell align="left">{approvedTimestamp}</TableCell>
                        <TableCell align="left">{tier}</TableCell>
                        <TableCell align="left">{country}</TableCell>
                        <TableCell align="left">{createdAt}</TableCell>
                        <TableCell align="right">
                          <UserMoreMenu />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={walletAddress} style={{backgroundColor: '#151517', color: '#FBAE48'}} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[pagination.limit]}
            component="div"
            count={pagination.totalRecords}
            rowsPerPage={pagination.limit}
            page={pageNo - 1}
            onPageChange={handleChangePage}
          />
        </Card>
      </Container>
    </Page>
  );
}
