import * as React from "react";
// import { filter } from 'lodash';
import { Icon } from "@iconify/react";
// import { sentenceCase } from 'change-case';
import { useState, useEffect } from "react";
import copyOutline from "@iconify/icons-eva/copy-outline";
import plusFill from "@iconify/icons-eva/plus-fill";
import Box from "@mui/material/Box";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import copy from "copy-to-clipboard";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import { compactAddress } from "../contractData/function";
import { toaster } from "../utils/toast";
import ArrowDownFill from "@iconify/icons-eva/arrow-down-fill";
import Collapse from "@kunukn/react-collapse";
import HexagonLoader from "../assets/refarmo_loader.svg";

import { web3 } from "../web3";

import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";

import Page from "../components/Page";
// import Label from '../components/Label';
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import { UserListHead, UserMoreMenu } from "../components/_dashboard/user";
import USERLIST from "../_mocks_/user";
import MetamaskImg from "../assets/mm.png";
import { services } from "../services";
// import { service } from '../../services/web3.service';
import moment from "moment";
import { TimeStampToDateString } from "src/contractData/function";

// ----------------------------------------------------------------------
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "30px",
  boxShadow: 24,
  p: 4,
};

const TABLE_HEAD = [
  { id: "srNo", label: "Sr No", alignRight: false },
  { id: "poolName", label: "Pool Name", alignRight: false },
  { id: "contractAddress", label: "Contract Address", alignRight: false },
  { id: "tokenAddress", label: "Token Address", alignRight: false },
  { id: "network", label: "Network", alignRight: false },
  { id: "date", label: "Date", alignRight: false },
  { id: "phaseNo", label: "Phase No", alignRight: false },
  { id: "rewards", label: "Rewards", alignRight: false },
  { id: "pending", label: "Pending Records", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(
//       array,
//       (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
//     );
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

export default function User() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState("");
  const [order, setOrder] = useState("asc");
  const [dropdownChange, setDropdownChange] = useState("Completed");
  // const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  // const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [claim, setClaim] = useState([]);
  const [allClaimPools, setAllClaimPools] = useState([]);
  const [pendingData, setPendingData] = useState(false);
  const [isDeployedPools, setIsDeployedPools] = useState(false);
  const [deployedPools, setDeployedPools] = useState([]);
  const [selectedBtn, setSelectedBtn] = useState("alreadyUpdated");
  const [web3Data, setWeb3Data] = useState({
    isLoggedIn: false,
    accounts: [],
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleFilterByName = (event) => {
  //   setFilterName(event.target.value);
  // };

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  // const filteredUsers = applySortFilter(
  //   USERLIST,
  //   getComparator(order, orderBy),
  //   filterName
  // );
  // check metamask connected or not
  const checkIsConnected = () => {
    web3.eth.getAccounts(function (err, accounts) {
      if (err || accounts.length === 0) {
        setWeb3Data({
          isLoggedIn: false,
          accounts: [],
        });
      } else {
        setWeb3Data({
          isLoggedIn: true,
          accounts: accounts,
        });
      }
    });
  };

  // connect metamask
  const connectMetamask = async () => {
    try {
      const getAcountDetails = await services.enableMetamask();
      setWeb3Data(getAcountDetails);
      if (getAcountDetails.isLoggedIn) {
        navigate("/dashboard/addclaimpools");
      }
    } catch (err) {
      console.log("error is:", err);
    }
  };

  const navigateToPage = (route) => {
    navigate(route, { replace: true });
  };

  useEffect(() => {
    fetchData(page + 1);
    callDeployedPools(page + 1);
  }, [page]);
  async function fetchData(page) {
    const url = `/claim/dump-list?page=${page}`;
    const sendRequest = await services.Get(url);
    if (sendRequest.status === 200) {
      let data = sendRequest.data.data;
      setIsDeployedPools(false);
      setPendingData(data);
      checkIsConnected();
      return true;
    }
  }

  const isUserNotFound = claim.length === 0;

  const callDeployedPools = async (page) => {
    const url = `/claim/list?page=${page}`;
    // const url = `/claim/list?&isSnft=true&&page=${page}`;

    const sendRequest = await services.Get(url);
    if (sendRequest.status === 200) {
      let data = sendRequest.data.data;
      setTotalPage(sendRequest.data.pagination.totalRecords);
      setIsDeployedPools(true);
      setDeployedPools(data);
      setClaim(data);
      checkIsConnected();
      window.scrollTo(0, 0);
      setDropdownChange("Completed");
    }
  };
  const isActive = (btn) => {
    if (btn === selectedBtn) return "active";
  };
  const isToggleClick = (checked, _id) => {
    dumpAction(!checked, _id);
  };
  const dumpAction = async (status, _id) => {
    const url = "/claim/edit";
    const payload = { claimId: _id, isDisabledBit: status };
    const sendRequest = await services.Post(url, payload);
    if (sendRequest.status === 200) {
      return toaster(`${"Pool Status Updated Successfully"}`, "success");
    }
  };

  const copyToClipboard = (address) => {
    copy(address);
    return toaster(`${"Copied!"}`, "success");
  };

  const [isOpen1, setIsOpen1] = React.useState(false);

  const onInit = () => {
    setIsOpen1(false);
  };
  const applyFilter = (type) => {
    const _data = deployedPools.filter((ele) => ele.vestingType === type);
    setClaim(_data);
    onInit();
    setDropdownChange(type);
  };

  return (
    <Page title="Manage Claims | Reformo Snapshot">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Claim Pools
          </Typography>
          <ButtonList>
            <Button
              variant="contained"
              className={isActive("pending")}
              // component={RouterLink}
              to=""
              onClick={() => {
                setClaim(pendingData);
                setSelectedBtn("pending");
              }}
              // startIcon={<Icon icon={plusFill} />}
              sx={{ background: "linear-gradient(90deg, #dc1fff 0%, #03e1ff 50%, #00ffa3 100%)" }}
              style={{ color: "#000000" }}
            >
              Upcoming
            </Button>
            <Button
              variant="contained"
              className={isActive("pending")}
              // component={RouterLink}
              to=""
              onClick={() => {
                setClaim(pendingData);
                setSelectedBtn("pending");
              }}
              // startIcon={<Icon icon={plusFill} />}
              sx={{ background: "linear-gradient(90deg, #dc1fff 0%, #03e1ff 50%, #00ffa3 100%)" }}
              style={{ color: "#000000" }}
            >
              Pending
            </Button>

            <Button
              className={isActive("alreadyUpdated")}
              variant="contained"
              // component={RouterLink}
              to=""
              onClick={() => {
                setClaim(deployedPools);
                setSelectedBtn("alreadyUpdated");
                setIsOpen1((state) => !state);
                setDropdownChange("Completed");
              }}
              sx={{ background: "linear-gradient(90deg, #dc1fff 0%, #03e1ff 50%, #00ffa3 100%)" }}
              style={{ color: "#000000" }}
            >
              {dropdownChange}
              <Icon
                icon={ArrowDownFill}
                width={18}
                height={18}
                onClick={() => setIsOpen1((state) => !state)}
              />
            </Button>
            <CompletedBox>
              <Collapse onInit={() => onInit()} isOpen={isOpen1}>
                <ul>
                  <li onClick={() => applyFilter("linear")} style={{backgroundColor: '#151517'}}>Linear</li>
                  <li onClick={() => applyFilter("monthly")} style={{backgroundColor: '#151517'}}>Monthly</li>
                  {/* <li onClick={() => applyFilter("merkle")}>Merkle</li> */}
                </ul>
              </Collapse>
            </CompletedBox>
            <Button
              variant="contained"
              to=""
              onClick={() => {
                web3Data.isLoggedIn
                  ? navigateToPage("/dashboard/addclaimpools")
                  : handleOpen();
              }}
              startIcon={<Icon icon={plusFill} />}
              sx={{ background: "linear-gradient(90deg, #dc1fff 0%, #03e1ff 50%, #00ffa3 100%)" }}
              style={{ color: "#000000" }}
            >
              Add Claim Pools
            </Button>
          </ButtonList>
        </Stack>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <h3 style={{ textAlign: "center" }}>
                Please connect to your Wallet
              </h3>
              <MetamaskBTN
                onClick={() => {
                  connectMetamask();
                }}
              >
                <img src={MetamaskImg} alt="" /> Connect Metamask
              </MetamaskBTN>
            </Typography>
          </Box>
        </Modal>
        <Card style={{backgroundColor: '#151517'}}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {claim.map((row, index) => {
                    const {
                      _id,
                      name,
                      contractAddress,
                      tokenAddress,
                      networkSymbol,
                      timestamp,
                      phaseNo,
                      amount,
                      data,
                      isDisabledBit,
                      isPending,
                    } = row;
                    return (
                      <TableRow hover key={_id} tabIndex={-1}>
                        <TableCell
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                          spacing={2}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                        >
                          {compactAddress(contractAddress)}
                          <span
                            style={{
                              position: "relative",
                              top: "4px",
                              cursor: "pointer",
                            }}
                          >
                            <Icon
                              icon={copyOutline}
                              onClick={() => copyToClipboard(contractAddress)}
                              height={18}
                              width={18}
                            />
                          </span>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                        >
                          {compactAddress(tokenAddress)}
                          <span
                            style={{
                              position: "relative",
                              top: "4px",
                              cursor: "pointer",
                            }}
                          >
                            <Icon
                              icon={copyOutline}
                              onClick={() => copyToClipboard(tokenAddress)}
                              height={18}
                              width={18}
                            />
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                          align="left"
                        >
                          {" "}
                          {networkSymbol}{" "}
                        </TableCell>
                        <TableCell
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                          align="left"
                        >
                          {TimeStampToDateString(timestamp)}
                          {/* {moment(timestamp * 1000).format(
                            'YYYY-MM-DD h:mm:ss a'
                          )} */}
                        </TableCell>
                        <TableCell
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                          align="left"
                        >
                          {phaseNo}
                        </TableCell>
                        <TableCell
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                          align="left"
                        >
                          {amount}
                        </TableCell>
                        <TableCell
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                          align="left"
                        >
                          {selectedBtn == "pending" ? (data ? data : "") : "-"}
                        </TableCell>
                        {/* <TableCell align='right'>{data.length}</TableCell> */}
                        {selectedBtn === "alreadyUpdated" && (
                          <TableCell
                            align="center"
                            style={{ padding: "14px", whiteSpace: "nowrap" }}
                          >
                            <CustomSwitch>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  value={_id}
                                  defaultChecked={!isDisabledBit}
                                  onChange={(e) =>
                                    isToggleClick(
                                      e.target.checked,
                                      e.target.value
                                    )
                                  }
                                />
                                <span className="slider round"></span>
                              </label>
                            </CustomSwitch>
                          </TableCell>
                        )}
                        <TableCell
                          align="right"
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                        >
                          <UserMoreMenu
                            menu="claimPool"
                            id={_id}
                            url={
                              networkSymbol === "SOL"
                                ? selectedBtn === "pending"
                                  ? `/dashboard/editPendingAddSolanapools/${_id}`
                                  : `/dashboard/editCompletedAddSolanapools/${_id}`
                                : selectedBtn === "pending"
                                ? `/dashboard/editPendingClaimpools/${_id}`
                                : `/dashboard/editCompletedClaimpools/${_id}`
                            }

                            // menu='pools'
                            // setSelectedPool={setSelectedPool}
                            // setAddFundsModal={setAddFundsModal}
                            // id={id}
                            // contractId={pool.contractId}
                            // deletePool={deletePool}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <LoaderOuter>
                          <div className="loader" style={{height:"150px", width:"150px"}}>
                          <img className="loader-img" src={HexagonLoader}  alt="" />
                          </div>
                        </LoaderOuter>
                        <SearchNotFound style={{backgroundColor: '#151517', color: '#FBAE48'}} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[20]}
            component="div"
            count={totalPage}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}

const CustomSwitch = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 23px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background: linear-gradient(90deg, #dc1fff 0%, #03e1ff 50%, #00ffa3 100%);
    border-radius: 10px;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #FBAE48;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

const LoaderOuter = styled.div`
  dispaly: flex;
  align-items: center;
  justify-content: center;
  .loader {
    // border: 5px solid #f3f3f3;
    // border-top: 5px solid #5bb3fc;
    // border-radius: 50%;
    width: 45px;
    height: 45px;
    margin: 20px auto;
    // animation: spin 0.5s linear infinite;
    // @keyframes spin {
    //   0% {
    //     transform: rotate(0deg);
    //   }
    //   100% {
    //     transform: rotate(360deg);
    //   }
    // }
  }
  @keyframes rotate360 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .loader-img {
    animation: rotate360 2s linear infinite;
  }
`;

const ButtonList = styled.div`
  display: flex;
  align-items: center;
  Button {
    margin: 0px 5px;
    &.active {
      background: linear-gradient(90deg, #dc1fff 0%, #03e1ff 50%, #00ffa3 100%);
      border-radius: 10px;
    }
  }
`;

const MetamaskBTN = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  padding: 10px 30px;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  img {
    width: 45px;
    margin-right: 10px;
  }
`;

const CompletedBox = styled.div`
  position: relative;
  .collapse-css-transition {
    position: absolute;
    top: 22px;
    right: 0px;
    left: auto;
    z-index: 9;
    width: 200px;
    transition: height 252ms cubic-bezier(0.4, 0, 0.2, 1);
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  li {
    padding: 10px;
    cursor: pointer;
  }
`;
